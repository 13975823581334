var site = site || {};

site.template = site.template || {};

(function ($) {
  /**
   * Event listener to display the overlay.
   * Event trigger comes from module product_personalization_v1.
   */
  $(document).on('product.personalization.overlay', '.js-product', function (event, data) {
    Drupal.behaviors.productPersonalizationOverlayV1.launch(data);
  });

  Drupal.behaviors.productPersonalizationOverlayV1 = {
    /**
     * Drupal behavior method to handle the personalization overlay display and functionality.
     * After the 'product.personalization.overlay' event is fired, the product's data is passed to the launch method.
     * The product_personalization_overlay inline template is then rendered and displayed using generic.overlay.launch.
     * Once rendered, the personalization form submit rpc is initialized.
     * Form submission either results in a success message or errors displaying inline.
     * All messaging copy is handled within the personalization module in the CMS.
     */
    launch: function (data) {
      var that = this;
      var rendered = site.template.get({
        name: 'product_personalization_overlay',
        data: data
      });

      generic.overlay.launch({
        content: rendered,
        width: 830,
        maxWidth: '100%',
        height: 760,
        onComplete: function (response) {
          var $overlayContainer = $('.js-personalization-container');
          var $formContent = $('.js-personalization-form-content', $overlayContainer);
          var $form = $('.js-personalization-form', $overlayContainer);
          var $submit = $('.js-personalization-submit', $overlayContainer);
          var $fieldCharacters = $('.js-personalization-characters', $overlayContainer);
          var $fieldFormat = $('.js-personalization-select-format', $overlayContainer);
          var $fieldSkuBaseId = $('.js-personalization-sku-base-id', $overlayContainer);
          var $successContainer = $('.js-personalization-success', $overlayContainer);
          var $errorList = $('.js-error-messages', $overlayContainer);
          var $btnLoading = $('.js-loading-message', $overlayContainer);
          var monogramText;
          var monogramCharacters;
          var includePeriods;

          // Set sku_base_id
          $fieldSkuBaseId.val(data.SKU_BASE_ID);

          Drupal.behaviors.adaptivePlaceholders.setupDOM($fieldCharacters);
          Drupal.behaviors.selectBox.attach($form);

          $form.on('submit', function (e) {
            e.preventDefault();
            var errors = [];

            generic.showErrors([], $errorList, $form); // Reset errors
            var skuBaseId = data.SKU_BASE_ID;

            monogramCharacters = $fieldCharacters.val().toUpperCase();
            monogramCharacters = monogramCharacters.replace(/\s|\./g, '');

            // Validation
            if (monogramCharacters && monogramCharacters.length > 3) {
              errors.push({ key: 'js-error-personalization-count' });
            }
            if (monogramCharacters.length <= 0) {
              errors.push({ key: 'js-error-personalization-character' });
            }
            includePeriods = $fieldFormat.val();
            if (!includePeriods) {
              errors.push({ key: 'js-error-personalization-select-format' });
            }
            if (errors.length) {
              generic.showErrors(errors, $errorList, $form);

              return;
            }

            if (includePeriods == 1) {
              monogramText = monogramCharacters.split('').join('.') + '.';
            } else {
              monogramText = monogramCharacters;
            }

            var params = [
              {
                _SUBMIT: 'alter_monogram',
                COLLECTION_NAME: skuBaseId + ':1',
                MONOGRAM_TEXT: monogramText,
                action: 'add,monogram,save',
                SKU_BASE_ID: skuBaseId,
                COLLECTION_TYPE: 'MONO',
                COLLECTION_SUBTYPE: 'MONO'
              }
            ];

            // Show loading state
            $btnLoading.removeClass('hidden');
            $submit.addClass('hidden');

            var id = generic.jsonrpc.fetch({
              method: 'form.rpc',
              params: params,
              onSuccess: function (jsonRpcResponse) {
                $(document).trigger('loadCart.success', true);
                $formContent.hide();
                $successContainer.removeClass('hidden');
                $btnLoading.addClass('hidden');
                $submit.removeClass('hidden');
              },
              onFailure: function (jsonRpcResponse) {
                var errorObj = jsonRpcResponse.getError();
                var errorArray = errorObj.data.messages;

                generic.showErrors(errorArray, $errorList);
                console.log('personalization failure. errorObj = ', errorObj);
                $btnLoading.addClass('hidden');
                $submit.removeClass('hidden');
              }
            });
          });
        }
      });
    } // Launch
  };
})(jQuery);
